import { AxiosRequestHeaders } from 'axios'
import { excludeObjectKeys } from 'packages/utilities'
import { APIParams, getHeaders, HeaderKey, request, Response } from '../config/api'

const IAMService = {
  register({ options }: APIParams): Response {
    return request({
      method: 'post',
      headers: getHeaders(),
      url: `${import.meta.env.VITE_IAM_SERVICE}/public/sign-up/account/email`,
      ...options,
    })
  },
  loginByEmail({ options }: APIParams): Response {
    return request({
      method: 'post',
      headers: getHeaders(),
      url: `${import.meta.env.VITE_IAM_SERVICE}/log-in/account/email`,
      ...options,
    })
  },
  loginByGoogle({ options }: APIParams): Response {
    return request({
      method: 'post',
      headers: getHeaders(),
      url: `${import.meta.env.VITE_IAM_SERVICE}/connect/account/google`,
      ...options,
    })
  },
  logout(): Response {
    return request({
      method: 'post',
      headers: getHeaders(),
      url: `${import.meta.env.VITE_IAM_SERVICE}/log-out`,
    })
  },
  getIndustries(): Response {
    return request({
      headers: getHeaders(),
      url: `${import.meta.env.VITE_IAM_SERVICE}/industries`,
    })
  },
  createWorkspace({ options }: APIParams): Response {
    return request({
      headers: getHeaders(),
      url: `${import.meta.env.VITE_IAM_SERVICE}/organizations`,
      method: 'post',
      timeout: 60 * 1000,
      ...options,
    })
  },
  getWorkspaces({ options }: APIParams): Response {
    return request({
      headers: excludeObjectKeys(getHeaders() as object, [
        HeaderKey.OrganizationId,
      ]) as AxiosRequestHeaders,
      url: `${import.meta.env.VITE_IAM_SERVICE}/organizations`,
      ...options,
    })
  },
  getWorkspace({ options, variables }: APIParams<{ workspaceId: string }>): Response {
    return request({
      headers: excludeObjectKeys(getHeaders() as object, [
        HeaderKey.OrganizationId,
      ]) as AxiosRequestHeaders,
      url: `${import.meta.env.VITE_IAM_SERVICE}/organizations/${variables?.workspaceId}`,
      ...options,
    })
  },
  updateWorkspace({ options, variables }: APIParams<{ workspaceId: string }>): Response {
    return request({
      headers: getHeaders(),
      url: `${import.meta.env.VITE_IAM_SERVICE}/organizations/${variables?.workspaceId}`,
      method: 'put',
      ...options,
    })
  },
  deleteWorkspace({ options, variables }: APIParams<{ workspaceId: string }>): Response {
    return request({
      headers: getHeaders(),
      url: `${import.meta.env.VITE_IAM_SERVICE}/organizations/${variables?.workspaceId}`,
      method: 'delete',
      ...options,
    })
  },
  getRoles({ options }: APIParams): Response {
    return request({
      headers: getHeaders(),
      url: `${import.meta.env.VITE_IAM_SERVICE}/roles`,
      ...options,
    })
  },
  addMember({ options }: APIParams): Response {
    return request({
      headers: getHeaders(),
      url: `${import.meta.env.VITE_IAM_SERVICE}/members`,
      method: 'post',
      ...options,
    })
  },
  updateMember({
    options,
    variables,
  }: APIParams<{ memberId: number | string }>): Response {
    return request({
      headers: getHeaders(),
      url: `${import.meta.env.VITE_IAM_SERVICE}/members/${variables?.memberId}`,
      method: 'put',
      ...options,
    })
  },
  getMembers({ options }: APIParams): Response {
    return request({
      headers: getHeaders(),
      url: `${import.meta.env.VITE_IAM_SERVICE}/members`,
      ...options,
    })
  },

  getMemberOptions({ options }: APIParams): Response {
    return request({
      headers: getHeaders(),
      url: `${import.meta.env.VITE_IAM_SERVICE}/member-options`,
      ...options,
    })
  },

  getMemberDetails({ options, variables }: APIParams<{ memberId: number }>): Response {
    return request({
      headers: getHeaders(),
      url: `${import.meta.env.VITE_IAM_SERVICE}/members/${variables?.memberId}`,
      ...options,
    })
  },
  deleteMember({ options, variables }: APIParams<{ memberId: number }>): Response {
    return request({
      method: 'delete',
      headers: getHeaders(),
      url: `${import.meta.env.VITE_IAM_SERVICE}/members/${variables?.memberId}`,
      ...options,
    })
  },
  updateAccount({ options }: APIParams): Response {
    return request({
      headers: getHeaders(),
      url: `${import.meta.env.VITE_IAM_SERVICE}/accounts/me`,
      method: 'put',
      ...options,
    })
  },
  updateWorkspaceData({
    options,
    variables,
  }: APIParams<{ workspaceId: string }>): Response {
    return request({
      headers: getHeaders(),
      url: `${import.meta.env.VITE_IAM_SERVICE}/organizations/${variables?.workspaceId}`,
      method: 'put',
      ...options,
    })
  },
  initializeWorkspace({
    options,
    variables,
  }: APIParams<{ workspaceId: string }>): Response {
    return request({
      headers: getHeaders(),
      url: `${import.meta.env.VITE_IAM_SERVICE}/organizations/${
        variables?.workspaceId
      }/initialize`,
      method: 'post',
      ...options,
    })
  },
  getTokens(): Response {
    return request({
      headers: getHeaders(),
      url: `${import.meta.env.VITE_IAM_SERVICE}/tokens`,
    })
  },
  createToken({ options }: APIParams): Response {
    return request({
      headers: getHeaders(),
      url: `${import.meta.env.VITE_IAM_SERVICE}/tokens`,
      method: 'post',
      ...options,
    })
  },
  deleteToken({ options, variables }: APIParams<{ tokenId: number }>): Response {
    return request({
      headers: getHeaders(),
      url: `${import.meta.env.VITE_IAM_SERVICE}/tokens/${variables?.tokenId}`,
      method: 'delete',
      ...options,
    })
  },
  authMemberAccountWithToken({ options }: APIParams): Response {
    return request({
      headers: getHeaders(),
      url: `${import.meta.env.VITE_IAM_SERVICE}/auth/member/token`,
      method: 'post',
      ...options,
    })
  },
  getCurrentAccount(): Response {
    return request({
      headers: getHeaders(),
      url: `${import.meta.env.VITE_IAM_SERVICE}/me`,
    })
  },
  updatePassword({ options }: APIParams): Response {
    return request({
      headers: getHeaders(),
      url: `${import.meta.env.VITE_IAM_SERVICE}/accounts/me/password`,
      method: 'put',
      ...options,
    })
  },
  requestPasswordReset({ options }: APIParams): Response {
    return request({
      method: 'post',
      headers: getHeaders(),
      url: `${import.meta.env.VITE_IAM_SERVICE}/password-reset-request`,
      ...options,
    })
  },
  passwordReset({ options }: APIParams): Response {
    return request({
      method: 'post',
      headers: getHeaders(),
      url: `${import.meta.env.VITE_IAM_SERVICE}/password-reset`,
      ...options,
    })
  },
  passwordSetup({ options }: APIParams): Response {
    return request({
      method: 'post',
      headers: getHeaders(),
      url: `${import.meta.env.VITE_IAM_SERVICE}/password-setup`,
      ...options,
    })
  },
  getSubscriptionPlans(): Response {
    return request({
      headers: getHeaders(),
      url: `${import.meta.env.VITE_IAM_SERVICE}/plans`,
    })
  },
  getLocationWithPosAccounts({ options }: APIParams): Response {
    return request({
      headers: getHeaders(),
      url: `${import.meta.env.VITE_IAM_SERVICE}/pos-members`,
      ...options,
    })
  },
  createLocation({ options }: APIParams): Response {
    return request({
      headers: getHeaders(),
      url: `${import.meta.env.VITE_IAM_SERVICE}/pos-members`,
      method: 'post',
      ...options,
    })
  },
  updateLocation({ options, variables }: APIParams<{ locationId: number }>): Response {
    return request({
      headers: getHeaders(),
      url: `${import.meta.env.VITE_IAM_SERVICE}/locations/${variables?.locationId}`,
      method: 'put',
      ...options,
    })
  },
  deleteLocation({ variables }: APIParams<{ accountId: number }>): Response {
    return request({
      method: 'delete',
      headers: getHeaders(),
      url: `${import.meta.env.VITE_IAM_SERVICE}/pos-members/${variables?.accountId}`,
    })
  },
  resetPosAccountPassword({
    options,
    variables,
  }: APIParams<{ accountId: number }>): Response {
    return request({
      method: 'post',
      headers: getHeaders(),
      url: `${import.meta.env.VITE_IAM_SERVICE}/pos-members/${
        variables?.accountId
      }/reset-password`,
      ...options,
    })
  },

  submitOTP({ options }: APIParams): Response {
    return request({
      method: 'post',
      headers: getHeaders(),
      url: `${import.meta.env.VITE_IAM_SERVICE}/otp-submit`,
      ...options,
    })
  },

  resetOTP({ options }: APIParams): Response {
    return request({
      method: 'post',
      headers: getHeaders(),
      url: `${import.meta.env.VITE_IAM_SERVICE}/otp-reset`,
      ...options,
    })
  },

  unblockAccount({ options }: APIParams): Response {
    return request({
      method: 'post',
      headers: getHeaders(),
      url: `${import.meta.env.VITE_IAM_SERVICE}/unblock`,
      ...options,
    })
  },

  updateEmail({ options }: APIParams): Response {
    return request({
      method: 'post',
      headers: getHeaders(),
      url: `${import.meta.env.VITE_IAM_SERVICE}/recover`,
      ...options,
    })
  },

  verifyEmailInvitation({ options }: APIParams): Response {
    return request({
      method: 'post',
      headers: getHeaders(),
      url: `${import.meta.env.VITE_IAM_SERVICE}/verify-invitation`,
      ...options,
    })
  },

  getLocations({ options }: APIParams): Response {
    return request({
      headers: getHeaders(),
      url: `${import.meta.env.VITE_IAM_SERVICE}/locations`,
      ...options,
    })
  },

  reinviteMember({ options }: APIParams): Response {
    return request({
      method: 'post',
      headers: getHeaders(),
      url: `${import.meta.env.VITE_IAM_SERVICE}/members/resend-invitation`,
      ...options,
    })
  },

  getResources({ options }: APIParams): Response {
    return request({
      headers: getHeaders(),
      url: `${import.meta.env.VITE_IAM_SERVICE}/resources`,
      ...options,
    })
  },

  createRole({ options }: APIParams): Response {
    return request({
      method: 'post',
      headers: getHeaders(),
      url: `${import.meta.env.VITE_IAM_SERVICE}/roles`,
      ...options,
    })
  },

  updateRole({ options, variables }: APIParams<{ roleId: number }>): Response {
    return request({
      method: 'put',
      headers: getHeaders(),
      url: `${import.meta.env.VITE_IAM_SERVICE}/roles/${variables?.roleId}`,
      ...options,
    })
  },

  updateRolePermissions({ options, variables }: APIParams<{ roleId: number }>): Response {
    return request({
      method: 'put',
      headers: getHeaders(),
      url: `${import.meta.env.VITE_IAM_SERVICE}/roles/${variables?.roleId}/permissions`,
      ...options,
    })
  },

  deleteRole({ variables }: APIParams<{ roleId: number }>): Response {
    return request({
      method: 'delete',
      headers: getHeaders(),
      url: `${import.meta.env.VITE_IAM_SERVICE}/roles/${variables?.roleId}`,
    })
  },
}

export default IAMService
