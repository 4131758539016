// Routes that do not depend on the authentication status. User can always access these routes
export const independentRoutes = {
  redirect: '/redirect',
  oauth: '/oauth',
}

// User can only access these routes if they are not logged in yet
export const publicRoutes = {
  publicLogin: '/login',
  login: '/:organizationSlug/login',
  unblockAccount: '/unblock',
  expiredResetRequest: '/expired-reset-request',
  updateEmail: '/recover',
  passwordReset: '/password-reset',
  passwordResetRequest: '/password-reset-request',
  passwordSetup: '/password-setup',
  register: '/:organizationSlug/register',
  supplierPackaging: '/supplier-packings/:packingLinkId',
}

export const appRoutes = {
  ...publicRoutes,
  ...independentRoutes,
  root: '/',
  dashboard: '/dashboard',
  orders: '/orders',
  orderDetails: '/orders/:orderId',
  orderDetailsWithTab: '/orders/:orderId/:tab',
  packings: '/packings',
  vehicles: '/vehicles',
  delivery: '/delivery',
  customers: '/customers',
  revenue: '/revenue',
  expense: '/expense',
  commission: '/commission',
  settings: '/settings',
  workspaces: '/workspaces',
  packages: '/packages',
}
