import { AxiosError } from 'axios'
import { useContext, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery, useQueryClient } from 'react-query'

import IAMService from '@/services/IAM'
import { QueryKeys } from '@/services/types'
import { Solution } from '@/utilities/enum'

import { AuthContext, AuthData } from './AuthProvider'
import { authLocalItem } from './localItems'

export type User = {
  id: number
  email: string
  full_name?: string
  phone?: string
  created: string
  updated: string
  data: UserMetaData
  access_token?: string
  google_id?: string
  type: string
  otp_token: string
  password_change_required?: boolean
  password?: string
}

export type Customer = {
  filum_user_id: string
  name: string
  email: string
  phone: string
  avatar: string
  last_response: {
    metric_type: string
    rating: number
    created: string
    follow_up_text: string
  }
  latest_rating: {
    csat: number
    nps: number
    ces: number
  }
  number_of_responses: number
}

export enum PlatformFeedbackType {
  CampaignCes = 'campaign_ces',
  AutomatedActionCes = 'automated_action_ces',
}

export type UserMetaData = {
  onboarded?: boolean
  solution_highlighted?: boolean
  latest_organization_id?: string | null
  latest_organization_id_v2?: string | null
  language?: 'en' | 'vi'
  quickstart?: Record<
    Solution,
    {
      completed_steps?: number[]
      current_step?: number
    }
  >
  last_feedback_at_mappings?: Record<PlatformFeedbackType, string>
  company?: {
    name?: string
    tax_code?: string
    address?: string
    representative?: string
  }
  locations?: Record<string, any>[]
}

type UseUser = {
  user: User | undefined
  set: (user: User | undefined) => void
  invalidate: () => void
}

export const useUser = (): UseUser => {
  const { i18n } = useTranslation()
  const queryClient = useQueryClient()
  const authContext = useContext(AuthContext)
  const passwordRef = useRef('')

  const authData = authLocalItem.get() as AuthData | undefined
  const accessToken = authData?.accessToken
  const queryKeys = [QueryKeys.GetCurrentAccount, accessToken]

  const { data: user } = useQuery<User, AxiosError>(
    queryKeys,
    async () => {
      const res = await IAMService.getCurrentAccount()
      return { ...res.data, password: passwordRef.current }
    },
    {
      enabled: !!accessToken,
      staleTime: Infinity,
      onSuccess: user => {
        if (user?.data?.language && user.data.language !== i18n.language) {
          i18n.changeLanguage(user.data.language)
        }
      },
      // Not using handleAPIError here to prevent circular dependency
      onError: () => {
        authContext?.logout()
        set(undefined)
      },
    },
  )

  const set = (user: User | undefined) => {
    if (user?.password) passwordRef.current = user.password
    queryClient.setQueryData(queryKeys, user)
  }

  const invalidate = () => {
    queryClient.invalidateQueries(queryKeys)
  }

  return {
    user,
    set,
    invalidate,
  }
}
