import {
  BarChart12,
  BarChartSquareUp,
  Inbox01,
  PackagePlus,
  Route as RouteIcon,
  Settings01,
  ShoppingCart01,
  Star01,
  User01,
  Users01,
} from 'packages/ui'
import { generatePath } from 'react-router-dom'

import { appRoutes } from './appRoutes'

export enum FeatureKey {
  Dashboard = 'dashboard',
  Orders = 'orders',
  Packings = 'packings',
  Vehicles = 'vehicles',
  Delivery = 'delivery',
  Revenue = 'revenue',
  Expenses = 'expenses',
  Commissions = 'commissions',
  Customers = 'customers',
  Settings = 'settings',
  Workspaces = 'workspaces',
}

export interface FeatureItem {
  key: FeatureKey
  label: string
  icon: React.FC<React.SVGProps<SVGSVGElement>>
  href: string
  hasTeaser?: boolean
}

export const FeatureMappings: Record<string, FeatureItem> = {
  [FeatureKey.Orders]: {
    key: FeatureKey.Orders,
    label: 'Đơn hàng',
    icon: ShoppingCart01,
    href: generatePath(appRoutes.orders),
  },
  [FeatureKey.Packings]: {
    key: FeatureKey.Packings,
    label: 'Kiện hàng',
    icon: PackagePlus,
    href: generatePath(appRoutes.packages),
  },
  [FeatureKey.Vehicles]: {
    key: FeatureKey.Vehicles,
    label: 'Xe',
    icon: RouteIcon,
    href: generatePath(appRoutes.vehicles),
  },
  [FeatureKey.Delivery]: {
    key: FeatureKey.Delivery,
    label: 'Giao hàng',
    icon: Inbox01,
    href: generatePath(appRoutes.delivery),
  },
  [FeatureKey.Revenue]: {
    key: FeatureKey.Revenue,
    label: 'Doanh thu',
    icon: BarChart12,
    href: generatePath(appRoutes.revenue),
  },
  [FeatureKey.Expenses]: {
    key: FeatureKey.Expenses,
    label: 'Chi phí',
    icon: BarChartSquareUp,
    href: generatePath(appRoutes.expense),
  },
  [FeatureKey.Commissions]: {
    key: FeatureKey.Commissions,
    label: 'Hoa hồng',
    icon: Star01,
    href: generatePath(appRoutes.commission),
  },
  [FeatureKey.Customers]: {
    key: FeatureKey.Customers,
    label: 'Khách hàng',
    icon: User01,
    href: generatePath(appRoutes.customers),
  },
  [FeatureKey.Workspaces]: {
    key: FeatureKey.Workspaces,
    label: 'Tài khoản',
    icon: Users01,
    href: generatePath(appRoutes.workspaces),
  },
  [FeatureKey.Settings]: {
    key: FeatureKey.Settings,
    label: 'Cài đặt',
    icon: Settings01,
    href: generatePath(appRoutes.settings),
  },
}
