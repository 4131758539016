export enum QueryKeys {
  GetRoles = 'getRoles',
  GetMembers = 'getMembers',
  GetOrders = 'getOrders',
  GetOrderCount = 'getOrderCount',
  GetOrder = 'getOrder',
  GetWorkspaces = 'getWorkspaces',
  GetWarehouses = 'getWarehouses',
  GetSuppliers = 'getSuppliers',
  GetProductUnitOptions = 'getProductUnitOptions',
  GetCompanies = 'getCompanies',
  GetProductTypes = 'getProductTypes',
  GetPackageTypeOptions = 'getPackageTypeOptions',
  GetPricings = 'getPricings',
  GetPricingOptions = 'getPricingOptions',
  GetOrderPricings = 'getOrderPricings',
  GetCurrentAccount = 'getCurrentAccount',
  GetPackageTypes = 'getPackageTypes',
  GetOrderPackages = 'getOrderPackages',
  GetOrderPackageTrackings = 'getOrderPackageTrackings',
  GetResources = 'getResources',
  AdminGetWorkspaces = 'adminGetWorkspaces',
  GetOrderPackingLinks = 'getOrderPackingLinks',
  GetProductUnits = 'getProductUnits',
  GetCustomFields = 'getCustomFields',
  GetOrderLogs = 'getOrderLogs',
  GetCustomers = 'getCustomers',
  GetDocumentSignedUrls = 'getDocumentSignedUrls',
  GetStaffPackages = 'getStaffPackages',
}

export enum ErrorType {
  CallRecordingNotAvailable = 'CALL_RECORDING_NOT_AVAILABLE',
  MisingCallioData = 'MISSING_CALLIO_DATA',
}
