import 'dayjs/locale/en'
import 'dayjs/locale/vi'

import { ConfigProvider } from 'antd'
import en_US from 'antd/lib/locale/en_US'
import vi_VN from 'antd/lib/locale/vi_VN'
import { ErrorBoundary } from 'packages/ui'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import {
  BrowserRouter,
  Route,
  Redirect as RouterRedirect,
  Switch,
} from 'react-router-dom'

import PlatformLayout from '@/components/Layout/PlatformLayout'
import { appRoutes } from '@/config/appRoutes'
import { VI_LANGUAGE_CODES } from '@/config/i18n'
import { AuthProvider } from '@/contexts/AuthProvider'
import { useUser } from '@/contexts/useUser'
import { queryClient } from '@/services/reactQuery'

import dayjs from '../utilities/dayjs'

// Lazy load routes
const Login = React.lazy(() => import('./login'))
const PasswordReset = React.lazy(() => import('./password-reset'))
const PasswordResetRequest = React.lazy(() => import('./password-reset-request'))
const PasswordSetup = React.lazy(() => import('./password-setup'))
const UnblockAccount = React.lazy(() => import('./unblock-account'))
const UpdateEmail = React.lazy(() => import('./update-email'))
const ExpiredRequest = React.lazy(() => import('./expired-request'))
const Redirect = React.lazy(() => import('./redirect'))
const Settings = React.lazy(() => import('./settings'))
const SupplierPackaging = React.lazy(() => import('./supplier-packaging'))
const Register = React.lazy(() => import('./register'))

const Orders = React.lazy(() => import('./orders'))
const Packages = React.lazy(() => import('./packages'))
const Workspaces = React.lazy(() => import('./workspaces'))
const Customers = React.lazy(() => import('./customers'))

const Container: React.FC = () => {
  const { i18n } = useTranslation()
  // Map i18n languague with corresponding Antd locale file
  const getAntdLocale = (language: string) =>
    VI_LANGUAGE_CODES.includes(language) ? vi_VN : en_US

  const [antdLocale, setAntdLocale] = useState(() => getAntdLocale(i18n.language))

  useEffect(() => {
    dayjs.locale(i18n.language)
    setAntdLocale(getAntdLocale(i18n.language))
  }, [i18n.language])

  return (
    // Always fallback to en
    <ConfigProvider
      locale={antdLocale}
      theme={{
        token: {
          fontFamily: '"Montserrat", system-ui, Avenir, Helvetica, Arial, sans-serif',
        },
        components: {
          Radio: {
            wrapperMarginInlineEnd: 0,
            radioSize: 20,
          },
          Slider: {
            handleSize: 16,
            handleLineWidth: 4,
            handleLineWidthHover: 6,
          },
        },
      }}>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} position='bottom-right' />
        <div className='App'>
          <BrowserRouter>
            <ErrorBoundary>
              <AuthProvider>
                <RouteWrapper />
              </AuthProvider>
            </ErrorBoundary>
          </BrowserRouter>
        </div>
      </QueryClientProvider>
    </ConfigProvider>
  )
}

const RouteWrapper = () => (
  <Switch>
    <Route path={appRoutes.supplierPackaging} component={SupplierPackaging} />
    <Route path={appRoutes.register} component={Register} />
    <Route path={appRoutes.login} component={Login} />
    <Route path={appRoutes.publicLogin} component={Login} />
    <Route path={appRoutes.passwordReset} component={PasswordReset} />
    <Route path={appRoutes.passwordResetRequest} component={PasswordResetRequest} />
    <Route path={appRoutes.passwordSetup} component={PasswordSetup} />
    <Route path={appRoutes.unblockAccount} component={UnblockAccount} />
    <Route path={appRoutes.updateEmail} component={UpdateEmail} />
    <Route path={appRoutes.expiredResetRequest} component={ExpiredRequest} />
    <Route path={appRoutes.redirect} component={Redirect} />
    <Route path={appRoutes.root} component={App} />
    <RouterRedirect to={appRoutes.root} />
  </Switch>
)

const App = () => {
  // const { workspace, isLoading: isLoadingWorkspace } = useWorkspace()
  const { user } = useUser()
  const changePasswordRequired = user?.password_change_required

  if (changePasswordRequired) {
    const params = new URLSearchParams({ email: user.email })
    return <RouterRedirect to={`${appRoutes.passwordSetup}?${params.toString()}`} />
  }

  // if (!workspace || isLoadingWorkspace) {
  //   return <Loading />
  // }

  return (
    <Switch>
      <Route path={appRoutes.passwordSetup} component={PasswordSetup} />
      <Route path={appRoutes.supplierPackaging} component={SupplierPackaging} />
      <PlatformLayout>
        <Switch>
          <Route path={appRoutes.orders} component={Orders} />
          <Route path={appRoutes.settings} component={Settings} />
          <Route path={appRoutes.workspaces} component={Workspaces} />
          <Route path={appRoutes.customers} component={Customers} />
          <Route path={appRoutes.packages} component={Packages} />
          <RouterRedirect to={appRoutes.orders} />
        </Switch>
      </PlatformLayout>
    </Switch>
  )
}

export default Container
