export enum LocalItemDataType {
  JSON = 'json',
  String = 'string',
  Number = 'number',
  Boolean = 'boolean',
  Datetime = 'datetime',
}

export enum LocalItemKey {
  SegmentForm = 'segmentForm',
  Auth = 'auth',
  UserColumns = 'userColumns',
  CustomPropertyUserColumns = 'customPropertyUserColumns',
  CampaignForm = 'campaignForm',
  ActionForm = 'actionForm',
  SurveyResponses = 'surveyResponse',
  ActiveReports = 'activeReports',
  Reviews = 'reviews',
  TopicAnalysisColumns = 'topicAnalysisColumns',
  TicketTabs = 'ticketTabs',
  CustomColumns = 'customColumns',
}

export const getLocalItemWorkspaceKey = (
  workspaceSlug: string,
  key: LocalItemKey,
): string => {
  return `${workspaceSlug}-${key}`
}

type AnyType =
  | string
  | number
  | boolean
  | object
  | Record<string, unknown>
  | Date
  | null
  | undefined

class LocalItem {
  key: string
  dataType: LocalItemDataType

  constructor(key: string, dataType: LocalItemDataType) {
    this.key = key
    this.dataType = dataType
  }

  set(value: AnyType): void {
    if (value == null) return

    let stringifiedValue = ''
    if (this.dataType === LocalItemDataType.Number) {
      stringifiedValue = value.toString()
    } else if (this.dataType === LocalItemDataType.Datetime) {
      stringifiedValue = (value as Date).toISOString()
    } else if (this.dataType === LocalItemDataType.Boolean) {
      stringifiedValue = value.toString()
    } else if (this.dataType === LocalItemDataType.JSON) {
      stringifiedValue = JSON.stringify(value)
    } else {
      stringifiedValue = value as string
    }
    localStorage.setItem(this.key, stringifiedValue)
  }

  get(): AnyType {
    const value = localStorage.getItem(this.key)

    try {
      switch (this.dataType) {
        case LocalItemDataType.Number:
          return Number(value)
        case LocalItemDataType.Datetime:
          return value && new Date(value)
        case LocalItemDataType.Boolean:
          return value?.toLowerCase() === 'true'
        case LocalItemDataType.JSON:
          return value && JSON.parse(value)
        default:
          return value
      }
    } catch (error) {
      return null
    }
  }

  delete(): void {
    localStorage.removeItem(this.key)
  }
}

export default LocalItem
