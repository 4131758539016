import { Loading } from 'packages/ui'
import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { pdfjs } from 'react-pdf'
import './config/i18n'
import Container from './routes'
import './styles/main.scss'
import './styles/index.css'
import 'react-pdf/dist/Page/AnnotationLayer.css'
import 'react-pdf/dist/Page/TextLayer.css'

import { message } from 'antd'

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`

message.config({
  top: 50,
})

function start() {
  ReactDOM.render(
    <React.StrictMode>
      <Suspense fallback={<Loading spinProps={{ delay: 500 }} />}>
        <Container />
      </Suspense>
    </React.StrictMode>,
    document.getElementById('root'),
  )
}

start()
